import React from "react";
import '../App.css';
import GetInTouch from "../components/GetInTouch/GetInTouch";
import Hero from "../components/Hero/Hero";
import MyStory from "../components/MyStory/MyStory";
import Gallery from "../components/gallery/Gallery";
import Header from "../components/Header/Header";

const Home = () => {
  return (
    <div>
      
      <Hero />
      <MyStory />
      <Gallery/>
      <Header/>
      <GetInTouch />
      <a
        href="https://wa.me/7758863762"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      
    </div>
  );
};

export default Home;
