import React from 'react';
import './Gallery.css';


import img1 from '../../Assets/5.jpg';
import img2 from '../../Assets/6.jpeg';
import img3 from '../../Assets/7.jpg';
import img4 from '../../Assets/10.jpeg';
import img5 from '../../Assets/8.jpg';
import img6 from '../../Assets/9.jpg';
import img7 from '../../Assets/10.jpg';
import img8 from '../../Assets/11.jpg';


const Gallery = () => {
  return (
    <>
    <div className='g-all'>

        <div className='g-header'>
            <h1>Gallery</h1>
        </div>

        <div className='g-parent'>
            
        <div className='g-photo'>
                <img style={{borderRadius:'30px'}} src={img1} />
            </div>

            <div className='g-photo'>
                <img style={{borderRadius:'30px'}} src={img2} />
            </div>

            <div className='g-photo'>
                <img style={{borderRadius:'30px'}} src={img3}/>
            </div>

            <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img4} />
                </div>
            

           
        </div>


        <div className='g-parent'>
            
            <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img5} />
                </div>
    
                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img6} />
                </div>
    
                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img7} />
                </div>

                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img8}/>
                </div>
                
    
               
            </div>

<br/>

            


         
    </div>
    </>
  )
}

export default Gallery