import React, { useState } from "react";
import "./GetInTouch.css";
import { FaClock, FaFacebook, FaInstagram, FaLocationDot, FaPhone, FaYoutube } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { useRef } from 'react';
import Swal from "sweetalert2";

import emailjs from '@emailjs/browser';
const GetInTouch = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  function viewPage(URL) {
    window.open(URL, "_blank");
  }
  function clearInputs(){
    setName('');
    setMessage('');
    setEmail('');
  }

  const form = useRef();
  function handleSumbmit(e){
 

        e.preventDefault();
    
        emailjs.sendForm('service_jwik2jn', 'template_mlfxyxf', form.current, 'KqoX3xu6p9G_Ig3Zq')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          

  
    clearInputs();
  }
  return (
    <>
    <div className="get-in-touch">
      <h1>Get in touch with Us:</h1>
      <div className="get-in-touch-main">
        <div className="get-in-touch-offices">
          <div className="get-in-touch-office">
            <h3>Goa Office</h3>
            
            <p className="get-in-touch-office-data">
              <span>
                <FaLocationDot />
              </span>
              <span> Prakash Tattoo,near Jerry and bar restaurant,Calangute Beach,Calangute,goa,403516</span>
            </p>
            <p className="get-in-touch-office-data">
              <span>
                {" "}
                <FaClock/>{" "}
              </span>
              <span> Opens: 9am - 12:3pm </span>
            </p>
            <p className="get-in-touch-office-data">
              <span>
                {" "}
                <FaPhone />{" "}
              </span>
              <span> +91 7758863762 </span>
            </p>
            <p className="get-in-touch-office-data">
              <span>
                {" "}
                <TfiEmail />
              </span>
              <span> Lamaniprakash696@gmail.com</span>
            </p>
            <a href="https://www.instagram.com/prakash_tattoo?igsh=ajFlNDM4eGxwMnhz">
            <p className="get-in-touch-office-data">
              <span>
                {" "}
                <FaInstagram />
              </span>
              <span>prakash_tattoo </span>
            </p>
            </a>

            <a href="https://www.facebook.com/share/4gWz4E84YSozSovV/?mibextid=qi2Omg">
            <p className="get-in-touch-office-data">
              <span>
                {" "}
                <FaFacebook />
              </span>
              <span>Prakash Lamani </span>
            </p>
            </a>
            <a href="https://youtube.com/@prakashtattoogoa?si=xqs8yoWAtQIOE7xd">
            <p className="get-in-touch-office-data">
              <span>
                {" "}
                <FaYoutube />
              </span>
              <span>Prakash tattoo goa </span>
            </p>
            </a>
            <p
              onClick={(e) =>
                viewPage(
                  "https://maps.app.goo.gl/6gzrkmrLKuA36T8y5"
                )
              }
              className="get-in-touch-office-get-location"
            >
              Get location
            </p>
          </div>
         
        </div>
        <form className="get-in-touch-offices-form" ref={form} onSubmit={handleSumbmit}>
          <input
            type="text"
            name="name"
            placeholder="Write your name here"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            name="email"
            placeholder="Write your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            name="message"
            placeholder="Write your messages here"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button type="submit">
            Send it
          </button>
        </form>
      </div>
    </div>
     <div className="g-map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15375.473658160483!2d73.7574106!3d15.5451832!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfeb3621237ad3%3A0x871fd0c9e5898cb1!2sPrakash%20Tattoo%20Shop!5e0!3m2!1sen!2sin!4v1709634252922!5m2!1sen!2sin" ></iframe>
   </div>
   


   <div className="copyright">
    copyright by Prakash Tattoo@2024
   </div>
    </>
  );
};

export default GetInTouch;
