import React from 'react'
import './MyStory.css'
import img from './MyStoryImg.PNG'
import { FaLongArrowAltRight } from "react-icons/fa";


const MyStory = () => {
    return (
        <div className='myStory'>
            <div className='myStry-img-contanier'>
                <img src={img} alt='' />
            </div>
            <div className='myStory-text'>
                <h1>
                    Our
                    Story:
                </h1>
                <p>
                Welcome to Prakash Studio: Unveiling Your Journey in Ink, Calangute, Goa
                </p>
                <p>
                At Prakash Studio, we don't just ink, we illuminate stories. Nestled in the heart of Calangute, Goa, our studio is a testament to the vibrant fusion of art, culture, and individuality. Welcome to a space where every tattoo is a canvas for your personal narrative, expertly crafted by our skilled team of artists.
                </p>
                <p>
                Ready to embark on your tattoo journey? Explore our portfolio, meet our artists, and schedule your consultation at Prakash Studio. Your story is unique, and we're here to help you tell it in a way that is as individual as you are.
                </p>
            </div>
        </div>
    )
}

export default MyStory
