import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import './Header.css'

export default class Header extends Component {
  render() {
    return (
      <div  className="t-container">

<div className="t-title">
          <h1 style={{textAlign:'center',paddingBottom:'10px',fontFamily:'cursive',fontSize:'50px'}}>Testimonials</h1>
        </div>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        
        
          <div>
          
          
          <br/>
          <br/>
          <div className="myCarousel">
            <h3 style={{paddingBottom:'5px'}}>Rahul Bhowmick</h3>
            
            
            <br/>
            <p>
            Prakash Tattoo exceeded my expectations! From the warm welcome to the impeccable cleanliness, the entire experience was fantastic. Prakash himself is a true artist, taking the time to understand my vision and turning it into a stunning piece of art.
            </p>
            <br/>
            <br/>
          </div>
        </div>

        <div>
          
          <br/>
          <br/>
          <div className="myCarousel">
            <h3 style={{paddingBottom:'5px'}}> Subhan</h3>
            
            <br/>
            <p>
            Had my first tattoo done at Prakash Tattoo, and I couldn't be happier. Prakash and his team are not just skilled artists; they are friendly and patient. The studio is filled with positive vibes, and their commitment to hygiene is commendable. 
            </p>
            <br/>
            <br/>
          </div>
        </div>

        <div>

          <br/>
          <br/>
          <div className="myCarousel">
            <h3  style={{paddingBottom:'5px'}}>Vivek Tandle</h3>
            
            <br/>
            <p>
            Prakash Tattoo is a gem in Goa. The ambiance is laid-back yet professional, creating the perfect atmosphere for a tattoo session. Prakash is a true maestro, turning my vague idea into a breathtaking reality. 
            </p><br/>
            <br/>
          </div>
          </div>
          <div>
          <div className="myCarousel">
            <h3  style={{paddingBottom:'5px'}}>Samrudhi Desai</h3>
            
            <br/>
            <p>
            Had an incredible experience at Prakash Studio! The artistic flair of Prakash and his team is unmatched. The studio's cleanliness is commendable, making me feel comfortable throughout the session.
            </p><br/>
            <br/>
          </div>
          </div>
          <div>
          <div className="myCarousel">
            <h3  style={{paddingBottom:'5px'}}>Priyanka Naidu</h3>
            
            <br/>
            <p>
            Prakash and his team are true artists! The studio's relaxed yet professional atmosphere made my tattoo session enjoyable. Prakash's attention to detail and creative input elevated my design beyond my expectations. 
            </p><br/>
            <br/>
          </div>
        </div>
        
      </Carousel>
      </div>
    );
  }
}