import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Hero.css';
import img1 from '../../Assets/10.jpeg';
import img2 from '../../Assets/7.jpg';
import img3 from '../../Assets/9.jpg';

const Hero = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className='hero'>
      <Slider {...sliderSettings}>
        <div className='slider-item'>
          <img src={img1} alt='Image 1' />
          <a
        href="https://wa.me/7758863762">
          <div className='button-container'>
           
            <button >Book <br/>Appointment</button>
          </div>
          </a>
        </div>
        <div className='slider-item'>
          <img src={img2} alt='Image 2' />
          <a
        href="https://wa.me/7758863762">
          <div className='button-container'>
           
            <button >Book <br/> Appointment</button>
          </div>
          </a>
        </div>
        
        <div className='slider-item'>
          <img src={img3} alt='Image 3' />
           <a
        href="https://wa.me/7758863762">
          <div className='button-container'>
           
            <button >Book <br/>Appointment</button>
          </div>
          </a>
        </div>
      </Slider>
    </div>
  );
};

export default Hero;
